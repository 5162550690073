// import logo from './logo.svg';
import './App.css';

function App() {
  return (
    <div class="min-vh-100 d-flex flex-column">
      <main class="my-auto">
        <div class="container">
          <h1 class="page-title">We're coming soon !!</h1>
        </div>
      </main>
    </div>
  );
}

export default App;
